'use client'
import { Button, Container, Icon, Typography } from '@shc/ui'
import TextField from '@components/text-field'
import { type ComponentPropsWithoutRef, useState } from 'react'
import qs from 'qs'

export interface SearchHeroProps extends ComponentPropsWithoutRef<'div'> {
  title: string
  searchUrl: string
  locationDefault?: string
  searchPlaceholderText: string
}

// This is a simple form that redirects to an ACTUAL search page which has a more complex searchHero
const SearchHeroPortal = ({
  title,
  searchUrl,
  locationDefault = '',
  searchPlaceholderText,
  ...props
}: SearchHeroProps) => {
  const [query, setQuery] = useState('')
  const [location, setLocation] = useState(locationDefault)
  return (
    <div {...props} className="bg-primary-50">
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault()
          // order matters here; keep it alpha because algolia router sorts keys by alpha, otherwise additional routes get pushed to history
          const baseParams = { v: 1 }
          const queryParams: { q?: string; l?: string } = {}
          if (typeof query === 'string' && query.length > 0) queryParams.q = query
          if (typeof location === 'string' && location.length > 0) queryParams.l = location
          if (Object.keys(queryParams).length > 0) {
            const queryString = qs.stringify(
              { ...queryParams, ...baseParams },
              {
                format: 'RFC1738',
                encodeValuesOnly: true,
                arrayFormat: 'comma',
              }
            )
            window.location.href = `${searchUrl}?${queryString}`
          } else {
            window.location.href = searchUrl
          }
        }}>
        <Container className="grid grid-cols-12 col-span-12 col-start-1 xl:col-span-8 xl:col-start-3 py-6 md:py-8 lg:py-15 gap-4 md:gap-8">
          <div className="col-span-12 col-start-1 xl:col-span-8 xl:col-start-3">
            <Typography variant="h1" className="w-full">
              {title}
            </Typography>
          </div>
          {/* search form fields */}
          <div className="col-start-1 col-span-12 md:col-span-6 xl:col-start-3 xl:col-span-4">
            <TextField
              type="search"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              autoFocus={true}
              autoComplete="off"
              autoCorrect="off"
              spellCheck={false}
              name="q"
              label="Search term"
              hideLabel={true}
              startAdornment={<Icon className="h-3.5" icon="magnifying-glass" />}
              placeholder={searchPlaceholderText}
            />
          </div>
          <div className="col-span-12 md:col-span-4 xl:col-span-3">
            <TextField
              type="text"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              autoFocus={false}
              autoComplete="off"
              autoCorrect="off"
              spellCheck={false}
              label="Location"
              name="loc"
              hideLabel={true}
              startAdornment={<Icon className="h-3.5" icon="location-dot" />}
              placeholder="ZIP code, neighborhood or city"
            />
          </div>
          <div className="col-span-12 md:col-span-2 lg:col-span-1">
            <Button width="full" type="submit" aria-label="Search" className="before:rounded gap-0">
              <Icon className="h-5" icon="magnifying-glass" />
              <span className="pl-2 md:hidden">Search</span>
            </Button>
          </div>
        </Container>
      </form>
    </div>
  )
}
export default SearchHeroPortal
